import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LegalNoticeComponent } from './legal-notice/legal-notice.component';
import { PrivacyNoticeComponent } from './privacy-notice/privacy-notice.component';
import { CurriculumVitaeComponent } from './curriculum-vitae/curriculum-vitae.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'legal-notice', component: LegalNoticeComponent },
  { path: 'privacy-notice', component: PrivacyNoticeComponent },
  { path: 'curriculum-vitae', component: CurriculumVitaeComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
